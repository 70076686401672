import React from 'react'
import Layout from '../components/layout/'
import Callout from '../components/callout/'
import PageWrapper from '../components/pageWrapper'
import Header from '../components/header'
import styles from './styles/about.module.css'

const ThankYou = ({ data }) => {
  const stats = data.stats.edges[0].node

  return (
    <Layout callout={<Callout />}>
      <Header stats={stats} />
      <PageWrapper>
        <div className={styles.contentBlock}>
          <h1>{data.allContentfulThankYouPage.edges[0].node.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.allContentfulThankYouPage.edges[0].node.message
                  .childMarkdownRemark.html,
            }}
          />
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const thankYouPageQuery = graphql`
  query thankYouPageQuery {
    stats: allContentfulStats {
      edges {
        node {
          id
          booksSent
          kidsRegistered
        }
      }
    }
    allContentfulThankYouPage {
      edges {
        node {
          id
          title
          message {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default ThankYou
